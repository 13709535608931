import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import ImageResize from "tiptap-extension-resize-image";
import Youtube from "@tiptap/extension-youtube";
import Link from "@tiptap/extension-link";

const CustomYoutube = Youtube.extend({
  renderHTML(x) {
    // Call the parent renderHTML method to get the original iframe
    const originalIframe = this.parent?.(x);

    // Now, wrap the original content with a div
    return [
      "div",
      {
        class: "relative w-full my-3",
        style: "padding-top: 56.25%;",
      },
      // Insert the original iframe inside the div
      originalIframe,
    ];
  },
});
import React, { useCallback, useEffect, useRef, useState } from "react";
import { generateHTML } from "@tiptap/html";
import {
  FaBold,
  FaImage,
  FaItalic,
  FaListOl,
  FaListUl,
  FaQuoteLeft,
  FaUnderline,
  FaYoutube,
} from "react-icons/fa6";
import ImageUploaderModal from "@components/image-uploader-modal";
import { FaLink, FaUnlink, FaUpload } from "react-icons/fa";

const fontSizes = () => {
  const fontSizes: number[] = [];
  for (let size = 8; size <= 50; size += 2) {
    fontSizes.push(size);
  }
  return fontSizes;
};
const webSafeFonts: string[] = [
  "Times New Roman", // Serif
  "Georgia", // Serif
  "Garamond", // Serif
  "Palatino Linotype", // Serif
  "Arial", // Sans-Serif
  "Verdana", // Sans-Serif
  "Trebuchet MS", // Sans-Serif
  "Tahoma", // Sans-Serif
  "Courier New", // Monospace
  "Lucida Console", // Monospace
];

const MenuBar = ({
  editor,
  selectedFont,
  selectedFontSize,
}: {
  editor: any;
  selectedFont?: string;
  selectedFontSize?: number;
}) => {
  const selectFontRef = useRef(null);
  const selectFontSizeRef = useRef(null);
  useEffect(() => {
    const updateRefValue = (ref: any, value: any) => {
      if (ref && ref.current) {
        (ref.current as any).value = value || "";
      }
    };

    updateRefValue(selectFontRef, selectedFont);
    updateRefValue(selectFontSizeRef, selectedFontSize);
  }, [selectedFont, selectedFontSize]);
  if (!editor) {
    return null;
  }

  function addYoutubeVideo() {
    const url = prompt("Enter YouTube URL");

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
      });
      editor.commands.focus("start");
    }
  }

  function changeColor(color: string) {
    editor.chain().focus().setColor(color).run();
  }

  function changeFont(fontFamily: string) {
    editor.chain().focus().setMark("textStyle", { fontFamily }).run();
  }

  function changeFontSize(fontSize: number) {
    editor.chain().focus().setMark("textStyle", { fontSize }).run();
  }

  const setLink = () => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  };

  return (
    <div className={"flex flex-col gap-y-1"}>
      <div className={"flex gap-x-2 pb-2 border-b-2"}>
        <button
          onClick={(e) => {
            editor.chain().focus().toggleBold().run();
            e.preventDefault();
            return false;
          }}
          disabled={!editor.can().chain().focus().toggleBold().run()}
        >
          {editor.isActive("bold") ? (
            <FaBold className={"bg-gray-800 text-white rounded p-0.5"} />
          ) : (
            <FaBold className={"bg-gray-100 text-black rounded p-0.5"} />
          )}
        </button>
        <button
          onClick={(e) => {
            editor.chain().focus().toggleItalic().run();
            e.preventDefault();
            return false;
          }}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
        >
          {editor.isActive("italic") ? (
            <FaItalic className={"bg-gray-800 text-white rounded p-0.5"} />
          ) : (
            <FaItalic className={"bg-gray-100 text-black rounded p-0.5"} />
          )}{" "}
        </button>

        <button
          onClick={(e) => {
            editor.chain().focus().toggleUnderline().run();
            e.preventDefault();
            return false;
          }}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
        >
          {editor.isActive("underline") ? (
            <FaUnderline className={"bg-gray-800 text-white rounded p-0.5"} />
          ) : (
            <FaUnderline className={"bg-gray-100 text-black rounded p-0.5"} />
          )}{" "}
        </button>

        <button
          type={"button"}
          onClick={setLink}
          className={editor.isActive("link") ? "is-active" : ""}
        >
          <FaLink className={"bg-gray-800 text-white rounded p-0.5"} />
        </button>
        <button
          type={"button"}
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive("link")}
        >
          <FaUnlink className={"bg-gray-100 text-black rounded p-0.5"} />
        </button>

        <button
          onClick={(e) => {
            editor.chain().focus().toggleBlockquote().run();
            e.preventDefault();
            return false;
          }}
          disabled={!editor.can().chain().focus().toggleBlockquote().run()}
        >
          {editor.isActive("blockquote") ? (
            <FaQuoteLeft className={"bg-gray-800 text-white rounded p-0.5"} />
          ) : (
            <FaQuoteLeft className={"bg-gray-100 text-black rounded p-0.5"} />
          )}{" "}
        </button>

        <button
          onClick={(e) => {
            editor.chain().focus().toggleBulletList().run();
            e.preventDefault();
            return false;
          }}
        >
          {editor.isActive("bulletList") ? (
            <FaListUl className={"bg-gray-800 text-white rounded p-0.5"} />
          ) : (
            <FaListUl className={"bg-gray-100 text-black rounded p-0.5"} />
          )}{" "}
        </button>

        <button
          onClick={(e) => {
            editor.chain().focus().toggleOrderedList().run();
            e.preventDefault();
            return false;
          }}
        >
          {editor.isActive("orderedList") ? (
            <FaListOl className={"bg-gray-800 text-white rounded p-0.5"} />
          ) : (
            <FaListOl className={"bg-gray-100 text-black rounded p-0.5"} />
          )}{" "}
        </button>
        <button
          onClick={(e) => {
            const url = window.prompt("Image URL");

            if (url) {
              editor.chain().focus().setImage({ src: url }).run();
              editor.commands.focus("start");
            }
            e.preventDefault();
            return false;
          }}
        >
          <FaImage className={"bg-gray-800 text-white rounded p-0.5"} />
        </button>
        <ImageUploaderModal
          onImageSelect={(payload: { url: string }) => {
            if (payload.url) {
              editor.chain().focus().setImage({ src: payload.url }).run();
              editor.commands.focus("start");
            }
            return false;
          }}
        >
          <FaUpload className={"bg-gray-800 text-white rounded p-0.5"} />
        </ImageUploaderModal>
        <button
          type={"button"}
          onClick={(e) => {
            addYoutubeVideo();
            e.preventDefault();
            return false;
          }}
        >
          <FaYoutube className={"bg-gray-800 text-white rounded p-0.5"} />
        </button>
      </div>
      <div className={"flex gap-x-2 pb-2 border-b-2 items-center"}>
        <select
          className={"text-xs"}
          ref={selectFontRef}
          onChange={(e) => changeFont(e.target.value)}
        >
          <option value={""}>Font</option>
          {webSafeFonts.map((w) => (
            <option key={w} value={w}>
              {w}
            </option>
          ))}
        </select>
        <select
          className={"text-xs"}
          ref={selectFontSizeRef}
          onChange={(e) => changeFontSize(Number(e.target.value))}
        >
          <option value={""}>Size</option>
          {fontSizes().map((f) => (
            <option key={`${f}`} value={f}>
              {f}
            </option>
          ))}
        </select>
        <div className="flex items-center gap-2">
          <input
            type="color"
            onChange={(e) => changeColor(e.target.value)}
            className={"rich-text-editor-control"}
          />
          <span className="text-gray-700 text-xs">Font Color</span>
        </div>{" "}
      </div>
    </div>
  );
};

const extensions = [
  Color.configure({ types: ["textStyle", TextStyle.name, ListItem.name] }),
  StarterKit.configure({
    paragraph: {
      HTMLAttributes: {
        class: "mb-2",
      },
    },
    bulletList: {
      HTMLAttributes: {
        class: "list-disc list-inside",
      },
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      HTMLAttributes: {
        class: "list-decimal list-inside",
      },
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    blockquote: {
      HTMLAttributes: {
        class: "border-l-2 border-gray-300 pl-4 ml-4",
      },
    },
  }),
  Image,
  Underline,
  ImageResize,
  CustomYoutube.configure({
    HTMLAttributes: {
      class: "absolute top-0 left-0 w-full h-full",
    },
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: "https",
    HTMLAttributes: {
      class: "link text-blue-600",
    },
  }),
  TextStyle.extend({
    addAttributes() {
      return {
        fontFamily: {
          default: null,
          parseHTML: (element) => element.style.fontFamily.replace(/["']/g, ""), // Remove quotes from font-family
          renderHTML: (attributes) => {
            if (!attributes.fontFamily) {
              return {};
            }
            return {
              style: `font-family: ${attributes.fontFamily}`,
            };
          },
        },
        fontSize: {
          default: null,
          parseHTML: (element) => element.style.fontSize.replace("px", ""),
          renderHTML: (attributes) => {
            if (!attributes.fontSize) {
              return {};
            }
            return {
              style: `font-size: ${attributes.fontSize}px`,
            };
          },
        },
      };
    },
  }),
];

export default function TiptapEditor({
  content,
  onUpdate,
  height,
  label,
}: {
  content: string;
  onUpdate: any;
  height?: number;
  label?: string;
}) {
  const [selectedFont, setSelectedFont] = useState();
  const [selectedFontSize, setSelectedFontSize] = useState();
  const editor = useEditor({
    extensions,
    content,
    onUpdate: (e) => onUpdate(generateHTML(e.editor.getJSON(), extensions)),
    onTransaction({ editor }) {
      const { fontFamily, fontSize } = editor.getAttributes("textStyle");
      setSelectedFont(fontFamily);
      setSelectedFontSize(fontSize);
    },
  });

  useEffect(() => {
    if (!editor) return;
    editor.commands.setContent(content);
  }, [content]);
  return (
    <div className={"flex flex-col gap-y-2"}>
      {label && <div className={"label-text"}>{label}</div>}
      <div
        className={"border-2 rounded p-2 min-h-40 block overflow-scroll"}
        style={{ height }}
      >
        <MenuBar
          editor={editor}
          selectedFont={selectedFont}
          selectedFontSize={selectedFontSize}
        />
        <EditorContent editor={editor} />
      </div>
    </div>
  );
}
